import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { BusinessManagementService } from 'src/app/shared/services/business-management.service';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GetServiceService {
  activeGSTIN: any;
  defaultCriteria: any;

  constructor(private http: HttpClient, private BMS: BusinessManagementService) { }

  viewStats(data :any, date:any): Observable<any> {
    let response;
    if(date){
      response = this.http.get(`${environment.apiUrl}/scheduler/forcefetch?gstin=${data.gstn}&nature=${data.type}&date=${date}&page=0&size=5`);
    }else{
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type}&page=0&size=5`);
    }
    return response;
  }

  getStats(data:any, date:any): Observable<any> {
    let response;
    if (date) {
      response = this.http.get(`${environment.apiUrl}/scheduler/forcefetch?gstin=${data.gstn}&nature=${data.type}&date=${date}&page=0&size=5`);
    } else {
      response = this.http.get(`${environment.apiUrl}/scheduler/stats?gstin=${data.gstn}&nature=${data.type}&page=0&size=5 `);
    }
    return response;
  }
  getFailedEWBs(pageIndex:any): Observable<any> {
    this.BMS.getActiveBusiness().pipe( take(1),).subscribe((activ) => this.activeGSTIN = activ);
    let response;
      response = this.http.get(`${environment.apiUrl}/ewb/extnErrors?page=${pageIndex}&size=1000&gstin=${this.activeGSTIN.gstn}`);
    
    return response;
  }
  
}
