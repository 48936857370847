import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../shared/services/loader.service';
import { BusinessManagementService } from '../shared/services/business-management.service';

import { Subject } from 'rxjs';
import { ToasterService } from '../shared/services/toaster.service';
import { HeaderComponent } from '../shared/components/header/header.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuthService } from '../shared/services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  showDashboardButton = false;
  showUtilityButton = false;
  isOrganisation = false;
  subsctiption: any;
  constructor(
    private router: Router,
    private BMS: BusinessManagementService,
    private toaster: ToasterService,
    private auth: AuthService,
    
  ) {}
  
  ngOnInit() {
    this.subsctiption = this.BMS.activeBusiness.subscribe((res) => {
      if (res)
        this.isOrganisation = res.isOrganisation;
    })
    if (this.router.url.indexOf('home') > -1) {
      this.showDashboardButton = false;
    } else {
      this.showDashboardButton = true;
    }
    if (this.router.url.indexOf('/qrscan') > -1) {
      this.showUtilityButton = false;
    } else {
      this.showUtilityButton = true;
    }
    //this.getGstinList();

  }
  //  getGstinList(){
  //     this.BMS.getMyBusiness().subscribe(res => {
  //       localStorage.setItem('businesses', JSON.stringify(res));
  //       this.auth.setLoginIndicator(2)
  //     });
  //  }

  tabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.router.navigate(['/dashboard']);
    } else if (!this.isOrganisation) {
      if (event.index === 1) {
        this.router.navigate(['widgets/godowns']);
      }
      else if (event.index === 2) {
        this.router.navigate(['widgets/watch-list']);
      }
      else if (event.index === 3) {
        this.router.navigate(['/qrscan']);
      }
    }
    else {
      this.toaster.warning("Please select GSTIN from above");
    }

  }
  ngOnDestroy() {
    if(this.subsctiption){this.subsctiption.unsubscribe();}
    
    //this.subsctiption = new Subject();
  }
}
