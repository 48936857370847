


export const REGEX = {
    gstin: "^(([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$)|([0-9]{4}[a-zA-Z]{3}[0-9]{5}[UO]{1}[N][a-zA-Z0-9]{1})|(URP)$)",
    vehicle: "[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{1,5}",
    decimal: "^([0-9]{1,13}(\.[0-9]{1,2})?|([0-9]{1,13}\.?))$",
    decimalToTwo: /^[0-9]{1,8}(\.\d{1,2})?$/
}

export const PART_B = {
    vehicleNo: '',
    transNo: '',
    transDocDate: '',
    quantity: '',
}

export const MONTH_LIST = [
    { key: 'January', value: 'Jan' },
    { key: 'February', value: 'feb' },
    { key: 'March', value: 'Mar' },
    { key: 'April', value: 'Apr' },
    { key: 'May', value: 'May' },
    { key: 'June', value: 'Jun' },
    { key: 'July', value: 'Jul' },
    { key: 'August', value: 'Aug' },
    { key: 'September', value: 'Sept' },
    { key: 'October', value: 'Oct' },
    { key: 'November', value: 'Nov' },
    { key: 'December', value: 'Dec' },
]

export const YEAR_LIST = [
    { key: '2019', value: '2019' },
    { key: '2020', value: '2020' },
]

export const CRITERIA_LIST = [
    { key: 'EWB Status', value: 'status' },
    { key: 'Reject Status', value: 'rejectStatus' },
    { key: 'Supply Type', value: 'supplyType' },
    { key: 'Transaction Type', value: 'transactionType' },
    { key: 'Sub Supply Type', value: 'subSupplyType' },
    { key: 'Document Type', value: 'docType' },
    { key: 'Consignor GSTIN', value: 'fromGstin' },
    { key: 'Dispatch State', value: 'actFromStateCode' },
    { key: 'Consignee GSTIN', value: 'toGstin' },
    { key: 'Delivery State', value: 'actToStateCode' },
    { key: 'Transportation Mode', value: 'transMode' },
    { key: 'Distance of Transportation', value: 'transDistance' },
    { key: 'Vehicle Type', value: 'vehicleType' }

]

export const REPORT_TYPE = [
    { key: 'My EWBs - All Operational EWBs', value: '3', keyVal: '6' },
    { key: 'Expiring Today', value: '3', keyVal: '5' },
    { key: 'Expired Yesterday', value: '3', keyVal: '4' },
    { key: 'Only Part A EWBs', value: '3', keyVal: '3' },
    { key: 'All EWBs - Inclusive of all products and latest transport details based on eway bill generation date', value: '2', keyVal: '2' },
    { key: 'Manual Archived EWBs', value: '1', keyVal: '1' },
    { key: 'System Archived EWBs', value: '0', keyVal: '0' }
]

export const SEARCH_QUERY_1 = {
    type: 'PARTB_NOT_UPDATED_FOR_12D',
    defaultquery: null,
    page: "0",
    size: 10,
    sortfield: "ewbDate",
    sortdir: "asc",
    addlquery: {
        "operator": "and",
        "criteria": []
    }
}

export const SEARCH_QUERY_2 = {
    type: '',
    page: "0",
    size: 10,
    sortfield: "ewbDate",
    sortdir: "asc",
    defaultquery: {
        "operator": "and",
        "criterias": [
            {
                "p": null,
                "o": "in",
                "v": null
            },
            {
                "p": "archived",
                "o": "eq",
                "v": "0"
            }
        ]
    },

}
