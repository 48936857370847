import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { BusinessManagementService } from '../../services/business-management.service';
import { ToasterService } from '../../services/toaster.service';
import { GetServiceService } from 'src/app/scheduler/services/get.service.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-auto-alert',
  templateUrl: './auto-alert.component.html',
  styleUrls: ['./auto-alert.component.scss']
})
export class AutoAlertComponent {
  private activeBusinessSubscription6!: Subscription;
  dataSource!: MatTableDataSource<Element>;
  isOrganisation!: boolean;  
  dtOptions: DataTables.Settings = {};
  errorList:any;
  errorTotalRecord:any;
  PageSize: any = 10;
  totalpageSize: any = [10,25,50,100];
  tableRowCount: any =10 ;
  pageIndex=0;

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private activeBusiness: BusinessManagementService,
    public dialogRef: MatDialogRef<AutoAlertComponent>,
    private schedulerService: GetServiceService,
  
    @Inject(MAT_DIALOG_DATA) public data: any,
    
  ) {}
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu:[5,15,25,50,100],
      processing: true
    };
    this.errorListData();

   
  }
  close(): void {
    this.dialogRef.close();
  }
  errorListData(){
    this.schedulerService.getFailedEWBs(this.pageIndex).subscribe(async (response:any) => {
      if (response.status === 1) {
        this.errorList = response.response.errorList;
        this.errorTotalRecord = response.response.totalRecords;       
        //this.toaster.success(`${response.message}`);  
        this.dataSource = new MatTableDataSource(this.errorList);   
      }else {
        this.toaster.warning(`${response.message}`);
      }
     
    });
  }
  download(event: any, type: any) {
    event.stopPropagation();
    if (this.isOrganisation) {
      this.router.navigateByUrl('/reports/' + type);
      return;
    } else {
      event.stopPropagation();
      let body: any = {};
      if (type == 'EWB_EXPIRING_TODAY_GODOWN_YES') {
        body['type'] = 'EWB_EXPIRED_YESTERDAY';
        body['defaultquery'] = null;
        body['page'] = "0";
        body['size'] = 10;
        body['sortfield'] = 'ewbDate';
        body['sortdir'] = 'desc';
          body['addlquery'] = {
            "operator": "and",
            "criterias": [
              {
                "p": "godownId",
                "o": "neq",
                "v": "-1"
              }
            ]
          }
        
      } 
      this.activeBusinessSubscription6 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {

        if (active !== null) {
          if (active.defaultquery !== null) {
            body['defaultquery'] = active.defaultquery;
          }
          this.activeBusiness.downloadEWBdata(body, active.gstn).subscribe((response) => {

            let blob = new Blob(['\ufeff' + response], { type: 'text/csv;charset=utf-8;' });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", `EWB_EXPIRED_YESTERDAY.csv`);
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
            // let downloadData = JSON.stringify(response);
            // var data = new Blob([downloadData], { type: 'text/csv' });
            // saveAs(data, 'text.csv');
          })
        } else {
          this.toaster.warning('Pls Select Gstin');
        }
      });
    }

  }
}
