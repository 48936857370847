import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { WidgetsService } from '../widgets.service';
import { BusinessManagementService } from '../../shared/services/business-management.service';
import { Router } from '@angular/router';
import { BaseResponse } from '../../_models/Response';
import { AuthService } from 'src/app/shared/services/auth.service';
import { take } from 'rxjs/operators';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-godowns',
  templateUrl: './godowns.component.html',
  styleUrls: ['./godowns.component.scss']
})
export class GodownsComponent {
  godownList: any;
  count: any;
  business$: any;
  loginInd: any;
  datas: any = [];
  gstins: any[];  
  isOrganisation: any;
  private activeBusinessSubscription6!: Subscription;
  constructor(
    private WS: WidgetsService,
    private activeBusiness: BusinessManagementService,
    private router: Router,
    private toaster: ToasterService,
    private auth: AuthService,
  ) {
    this.gstins = [];
  }

  ngOnInit() {
    this.datas = [];
    this.activeBusiness.fetchGstinList().subscribe((response) => {
      this.gstins = response;
    });

    this.business$ = this.activeBusiness.getActiveBusiness().subscribe((active) => {
      this.auth.getLoginIndicator().pipe(take(1)).subscribe((ind) => this.loginInd = ind);
      if (active === null && this.loginInd === 2) {
        this.toaster.warning('No GSTIN selected');
      } else if (active != null && this.loginInd === 2) {
        this.WS.godownEWBCount(active.gstn).subscribe((response: any) => {
          console.log("godownEWBCount3")     
          if (response.status === 1) {
            this.count = response.response;
            this.WS.fetchMyGodownList(active.gstn).subscribe((response: BaseResponse) => {
              if (response.status === 1) {
                this.godownList = response.response;
                this.datas = [];
                for (const godown of this.godownList) {
                  this.datas.push({
                    godownId: godown['godownId'],
                    godownName: godown['godownName'], count: this.getCount(godown['godownId'], this.count)
                  });
                }
              }
            }, (e) => {

            });
          }
        }, (e) => {

        });
      }
      if(active){
        if (active.gstn) {
          this.isOrganisation = false;
        }else{
          this.isOrganisation = true;
        }
      }
    });

  }

  viewGodown(godownId: any) {

    this.router.navigate([`/view-eway/${'VIEW_GODOWN'}/${godownId}`]);
  }

  getCount(godownId: any, godownCount: any) {
    const index = godownCount.findIndex((x: any) => x.godownId === godownId);
    return index !== -1 ? godownCount[index].ewbCount : 0;
  }
  download(event: any, type: any, ewbID:any) {
    event.stopPropagation();    
    if (this.isOrganisation) {
      this.router.navigateByUrl('/reports/' + type);
      return;
    } else {
      event.stopPropagation();
      let body: any = {};     

        body['type'] = 'My_Godown';
        body['defaultquery'] = null;
        body['page'] = "0";
        body['size'] = 10;
        body['sortfield'] = 'ewbDate';
        body['sortdir'] = 'desc';
          body['addlquery'] = {
            "operator": "and",
            "criterias": [
              {
                "p": "godownId",
                "o": "eq",
                "v": ewbID
              }
            ]
          }
     

      this.activeBusinessSubscription6 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {

        if (active !== null) {
          if (active.defaultquery !== null) {
            body['defaultquery'] = active.defaultquery;
          }
          this.activeBusiness.downloadEWBdata(body, active.gstn).subscribe((response) => {

            let blob = new Blob(['\ufeff' + response], { type: 'text/csv;charset=utf-8;' });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", `${type}.csv`);
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
            // let downloadData = JSON.stringify(response);
            // var data = new Blob([downloadData], { type: 'text/csv' });
            // saveAs(data, 'text.csv');
          })
        } else {
          this.toaster.warning('Pls Select Gstin');
        }
      });
    }

  }

  ngOnDestroy() {
    if (this.business$) {
      this.business$.unsubscribe();
    }
  }
}
