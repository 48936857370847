import { Component, OnInit, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { transform, useGeographic } from 'ol/proj';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import line from 'ol/geom/LineString';
import style from 'ol/style/Style';
import stroke from 'ol/style/Stroke';
import Stroke from 'ol/style/Stroke';
import LineString from 'ol/geom/LineString';
import { Text, Stroke as StrokeStyle, Fill, Icon, Circle as CircleStyle } from 'ol/style';
import * as moment from 'moment';
import { Point } from 'ol/geom';
import { FasttagService } from '../../services/fasttag.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Overlay from 'ol/Overlay';
@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.scss'],
})
export class ViewMapComponent implements OnInit {
  @ViewChild('mapCanvas') mapElement!: ElementRef;
  coordinates: any;
  place: any;
  markers: any = [];
  vectorSource: any;
  mapData: any;
  overlay!: Overlay;
  closer: any;
  container: any
  error: boolean = false;
  mapShow: boolean = false;
  popover: boolean = false
  message: any;
  refreshDataLoader: boolean = false;
  public map!: Map
  constructor(private fasttagService: FasttagService,
    public dialogRef: MatDialogRef<ViewMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  ngAfterViewInit() {
    this.map?.setTarget("map");
  }
  ngOnInit() {
    this.refreshDataLoader = true;
    this.container = document.getElementById('popup');
    useGeographic()
    this.overlay = new Overlay({
      element: this.container,
      autoPan: {
        animation: {
          duration: 20,
        },
      },
    });
    // this.map = new Map({
    //   layers: [
    //     new TileLayer({
    //       source: new OSM()
    //     })
    //   ],
    //   view: new View({
    //     center: [0, 0],
    //     zoom: 2
    //   })
    // });
    setTimeout(() => {
      this.fasttagService.fetchFastTagData(this.data).subscribe((res: any) => {
        this.refreshDataLoader = false;
        if(res.response){
          this.mapData = res.response;
        }else{
          this.error =true;
          this.message = res.message;
        }
       if (res.status == 1) {
          this.coordinates = res.response.map((m:any) => [m.longitude, m.latitude]);
          this.place = this.coordinates[Math.floor((this.coordinates.length / 2))]
          this.map = new Map({
            target: 'map',
            overlays: [this.overlay],
            layers: [
              new TileLayer({
                source: new OSM() 
              })
            ],
            view: new View({ 
              center: this.place,   
              zoom: 7
            })
          });
          setTimeout(() => {
            this.map.setTarget(this.mapElement.nativeElement);
            this.drawLineOnMap()
          }, 3000);
        }else{
          this.error =true;
          this.message = res.message;
        }
      }, (err) => {
        this.refreshDataLoader = false;
        this.error = true;
      })
    }, 5000)
  }

  drawLineOnMap() {
        this.map.on('singleclick', (evt) => {
          this.popover = true
          this.closer = document.getElementById('popup-closer');
          this.closer.onclick = () => {
            this.popover= false
            this.overlay.setPosition(undefined);
            this.closer.blur();
            return false;
          };
    // popup content
          const coordinate = evt.coordinate;
          const coordDistance = [] as any;
          let minCoord:any;
          this.mapData.forEach((m:any) => {
            const distance = (x0:any, y0:any, x1:any, y1:any) => Math.hypot(x1 - x0, y1 - y0);
            coordDistance.push(distance(m.longitude, m.latitude, coordinate[0], coordinate[1]))
          })
           minCoord = Math.min(...coordDistance);
             let indices = coordDistance.reduce((r:any, v:any, i:any)=> {
                 return r.concat(v === minCoord ? i : []);
             }, []);
            if (minCoord < 0.1) {
              let data;
          let innerHtmlPart1 = '<table style="font-size: 14px;font-family: Inter, system-ui, sans-serif;color: #007cae;  border-collapse: separate; border-spacing: 0 1em;">';
            let innerHTMLPart2 = '';
            const content = document.getElementById('popup-content') as any;            
            indices.forEach((indice:any, index:any )=>{
              const data = this.mapData[indice];
              
              innerHTMLPart2 = innerHTMLPart2 +  `
                 <tr><td style="width: 240px;"><b>${data.index}</b>.  ${data.plazaName.toUpperCase()}</td></tr>
                 <tr><td><div  style="margin-left:15px; margin-top: -15px;">${this.getFormattedTime(data.readerReadTime) + ' '}</div> <div style="margin-top: -15px;float: right;">  ${this.getLane(data.laneDir)} Lane</div></td></tr>
               `
            })
            let innerHtmlPart3 = '</table>';
            content.innerHTML = innerHtmlPart1 + innerHTMLPart2  + innerHtmlPart3;
              this.overlay.setPosition(coordinate);
            }
        });
    
        for (let i = 0; i < this.coordinates.length; i++) {
          this.coordinates[i] = transform(this.coordinates[i], 'EPSG:4326', 'EPSG:4326'); /* EPSG:4326 - WGS 84, latitude/longitude coordinate system based on the Earth's center of mass,                                                 used by the Global Positioning System among others.*/
        }
    
        const featureLine = new Feature({
          geometry: new line(this.coordinates)
        });
        const vectorLine = new VectorSource({});
        vectorLine.addFeature(featureLine);
    
        const vectorLineLayer = new VectorLayer({
          source: vectorLine,
          style: new style({
            stroke: new stroke({ color: '#007cae', width: 3 }) // line of the color and width
          })
        });
        this.map.addLayer(vectorLineLayer);
    
        this.setVectorPoints();
      }

  getLane(lane: any) {
    let day;
    switch (lane) {
      case 'N':
        day = 'NORTH';
        break;
      case 'W':
        day = 'WEST';
        break;
      case 'E':
        day = 'EAST';
        break;
      case 'S':
        day = 'SOUTH';
        break;
    }
    return day;
  }
  setVectorPoints() {
    let alreadyAddedPoints = [] as any;

    this.mapData.forEach((d: any, index:number) => {
      d.index = index + 1;
      const formatedReaderTime = moment(d.readerReadTime, 'DD/MM/YYYY h:mm:ss a').format("DD MMM YYYY h:mm:ss a");

      let marker = new Feature({
        geometry: new Point(transform([d.longitude, d.latitude], 'EPSG:4326', 'EPSG:4326'))
      });
      let offsetx = 0;
      let placementX = 0;
      if(alreadyAddedPoints.length > 0){
        alreadyAddedPoints.forEach((p:any) => {
          if((p.latitude == this.mapData[index].latitude) && (p.longitude == this.mapData[index].longitude)){
            offsetx = 13;
            placementX = 13;
          }
        })
      }
      alreadyAddedPoints.push(this.mapData[index]);
      marker.setStyle(new Style({
        // stroke: new Stroke({
        //   color: 'blue',
        //   width: 10,
        // }),
        text: new Text({
          text: `${d.index}`,
          font: '10',
          fill: new Fill({ color: '#007cae' }),
          offsetX: offsetx,
          offsetY: 0,
          placement: 'bottom',
          stroke: new Stroke({ color: '#007cae', width: 0.5 }),
        }),

        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({ color: '#007cae', width: 2 }),
          displacement:[placementX, 0]
        }),
      })
      )
      this.markers.push(marker);
    });
    this.vectorSource = new VectorSource({
      features: this.markers
    });

    const vectorLine = new VectorLayer({
      source: this.vectorSource
    });
    // setTimeout(())
    this.map.addLayer(vectorLine);
  }
  getFormattedTime(readerTime: any) {
    return readerTime
      ? moment(readerTime, 'DD/MM/YYYY h:mm:ss a').format(
        'DD MMM YYYY h:mm:ss a'
      )
      : null;
  }
  dismiss() {
    this.dialogRef.close();
  }
}