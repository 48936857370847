import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessManagementService } from '../shared/services/business-management.service';
import { DashboardService } from '../shared/services/dashboard.service';
import { take } from 'rxjs/operators';
import { SchedulerService } from '../shared/services/scheduler.service';
import * as moment from 'moment';
import { AuthService } from '../shared/services/auth.service';

import { EwbDetailsService } from '../shared/services/ewb-details.service';
import { ToasterService } from '../shared/services/toaster.service';
import { Subscription } from 'rxjs';
import { GetServiceService } from '../scheduler/services/get.service.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { AutoAlertComponent } from '../shared/components/auto-alert/auto-alert.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  gstins: any = [];
  lastUpdatedHistoricalTime: any;
  initialfetchdone!: boolean;
  userRole: any;
  currentUpdateFailed!: boolean;
  info: any;
  organisation!: string;
  scheduler3Failed!: boolean;
  parentOrganisationSeleced: any;
  count: any;
  schedularData:any;
  noActiveGstin!: boolean;
  isShowGstinHeader: boolean = false;
  isSchedulerInProgress = false;
  schedulerTime: any;
  isOrganisation: any;
  lastUpdatedCurrentTime: any;
  business$: Subscription | null;
  errorList:any;
  errorTotalRecord:any;
  private subscription!: Subscription;
  private activeBusinessSubscription1!: Subscription;
  private activeBusinessSubscription2!: Subscription;
  private activeBusinessSubscription3!: Subscription;
  private activeBusinessSubscription4!: Subscription;
  private activeBusinessSubscription5!: Subscription;
  private activeBusinessSubscription6!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activeBusiness: BusinessManagementService,
    private backend: DashboardService,
    private schedulerState: SchedulerService,
    private auth: AuthService,
    private toaster: ToasterService,
    private ewbDetailsService: EwbDetailsService,
    private schedulerService: GetServiceService,
    public dialog: MatDialog,
  ) {
    this.count = null;
    this.business$ = null;
    this.gstins = null;
  }


  getSubscriptionStatus(): string {
    if (this.info.expired) {
      return "expired";
    } else if (this.info.expiringToday) {
      return "expiringToday";
    } else if (this.info.expiringIn7Days) {
      return "expiringIn7Days";
    } else {
      return "active";
    }
  }
  ngOnInit() {
    //this.parentOrganisationSeleced = JSON.parse(localStorage.getItem('selectedOrganisation') || "");
    if(localStorage.getItem('selectedOrganisation')){
      this.parentOrganisationSeleced = JSON.parse(localStorage.getItem('selectedOrganisation') || '');
     }else{
       const organisations =  localStorage.getItem('organisations')
       this.parentOrganisationSeleced = organisations![0];
     }
    this.subscription = this.activeBusiness.getActiveBusiness().subscribe((bussiness) => {
      if (bussiness) {
        if (bussiness.gstn) {
          if (this.router.url.indexOf('home') > -1) {
            this.isOrganisation = false;
            this.noActiveGstin = false;
            this.refreshData({ gstn: bussiness.gstn });
          }
        } else if (bussiness.isOrganisation) {
          if (this.router.url.indexOf('home') > -1) {
            this.noActiveGstin = false;
            this.isOrganisation = true;
            this.refreshData(bussiness);
          }
        }
      }
    })
    const subs: any = this.auth.subscriptionSource.value;
    subs.enddate = moment(subs.enddate, 'YYYY-MM-DD');
    subs.validDays = subs.enddate.diff(moment().startOf('day'), 'days');
    if (subs.validDays <= 7 && subs.validDays >= 1) {
      subs.expiringIn7Days = true;
    } else if (subs.validDays === 0) {
      subs.expiringToday = true;
    } else if (subs.validDays < 0) {
      subs.expired = true;
    }
    this.info = subs;
    this.route.data.subscribe((data: any) => {
      if (!this.isOrganisation) {
        //this.count = data;
        this.gstins = data.gstinList;
        if(this.gstins.length === 1){
          this.isShowGstinHeader = true;
        }else{
          this.isShowGstinHeader = false;
        }
        // if (this.count.ewbCount !== undefined) {
        //   this.initialfetchdone = true;
        //   // if (this.count.ewbCount[6] && this.count.ewbCount[6].status === 1 && this.count.ewbCount[6].response.length > 0) {
        //   //   let stats = this.count.ewbCount[6].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   //   if (stats.length > 0) {
        //   //     this.initialfetchdone = true;
        //   //   } else {
        //   //     this.initialfetchdone = false;
        //   //   }
        //   // } else {
        //   //   this.initialfetchdone = false;
        //   // }
        //   // if (this.count.ewbCount[7] && this.count.ewbCount[7].status === 1 && this.count.ewbCount[7].response.length > 0) {
        //   //   let stats = this.count.ewbCount[7].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   //   if (stats.length > 0) {
        //   //     this.lastUpdatedCurrentTime = stats[0].end;
        //   //     this.lastUpdatedCurrentTime = moment(this.lastUpdatedCurrentTime, 'DD-MM-YYYY HH:mm:ss');
        //   //     // if (stats[0].status === 2) {
        //   //     //   this.currentUpdateFailed = true;
        //   //     // } else {
        //   //     //   this.currentUpdateFailed = false;
        //   //     // }
        //   //   } else {
        //   //     this.lastUpdatedCurrentTime = null;
        //   //   }
        //   // } else {
        //   //   this.lastUpdatedCurrentTime = null;
        //   // }
        //   // if (this.count.ewbCount[8] && this.count.ewbCount[8].status === 1 && this.count.ewbCount[8].response.length > 0) {
        //   //   let stats = this.count.ewbCount[8].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   //   if (stats.length > 0) {
        //   //     this.lastUpdatedHistoricalTime = stats[0].end;
        //   //     this.lastUpdatedHistoricalTime = moment(this.lastUpdatedHistoricalTime, 'DD-MM-YYYY HH:mm:ss');
        //   //   } else {
        //   //     this.lastUpdatedHistoricalTime = null;
        //   //   }
        //   // } else {
        //   //   this.lastUpdatedHistoricalTime = null;
        //   // }
        //   // if (this.count.ewbCount[9].status === 1 && this.count.ewbCount[9].response.length > 0) {
        //   //   let stats = this.count.ewbCount[9].response.filter((obj: any) => {
        //   //     return obj.status === 2;
        //   //   });
        //   //   if (this.count.ewbCount[9].response.length > 0 && this.count.ewbCount[9].response[0].status === 2) {
        //   //     this.scheduler3Failed = true;
        //   //   } else {
        //   //     this.scheduler3Failed = false;
        //   //   }
        //   // } else {
        //   //   this.scheduler3Failed;
        //   // }
        // } else {
        //   this.initialfetchdone = false;
        // }
        // if (this.count && this.count.ewbCount && this.count.ewbCount.length > 0) {
        //   this.count = data.ewbCount;
        //   this.initialfetchdone = true;
        // }
      } else {
        // this.count = data;
        // if (this.count !== undefined) {
        //   this.initialfetchdone = true;
        //   // if (this.count[6] && this.count[6].status === 1 && this.count[6].response.length > 0) {
        //   //   let stats = this.count[6].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   // }
        //   // if (this.count[7] && this.count[7].status === 1 && this.count[7].response.length > 0) {
        //   //   let stats = this.count[7].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   //   if (stats && stats.length > 0) {
        //   //     this.lastUpdatedCurrentTime = stats[0].end;
        //   //     this.lastUpdatedCurrentTime = moment(this.lastUpdatedCurrentTime, 'DD-MM-YYYY HH:mm:ss');
        //   //     if (stats[0].status === 2) {
        //   //       this.currentUpdateFailed = true;
        //   //     } else {
        //   //       this.currentUpdateFailed = false;
        //   //     }
        //   //   } else {
        //   //     this.lastUpdatedCurrentTime = null;
        //   //   }
        //   // } else {
        //   //   this.lastUpdatedCurrentTime = null;
        //   // }
        //   // if (this.count[8] && this.count[8].status === 1 && this.count[8].response.length > 0) {
        //   //   let stats = this.count[8].response.filter((obj: any) => {
        //   //     return obj.status !== 0;
        //   //   });
        //   //   if (stats && stats.length > 0) {
        //   //     this.lastUpdatedHistoricalTime = stats[0].end;
        //   //     this.lastUpdatedHistoricalTime = moment(this.lastUpdatedHistoricalTime, 'DD-MM-YYYY HH:mm:ss');
        //   //   } else {
        //   //     this.lastUpdatedHistoricalTime = null;
        //   //   }
        //   // } else {
        //   //   this.lastUpdatedHistoricalTime = null;
        //   // }
        //   // if (this.count[9] && this.count[9].status === 1 && this.count[9].response.length > 0) {
        //   //   let stats = this.count[9].response.filter((obj: any) => {
        //   //     return obj.status === 2;
        //   //   });
        //   //   if (this.count[9].response.length > 0 && this.count[9].response[0].status === 2) {
        //   //     this.scheduler3Failed = true;
        //   //   } else {
        //   //     this.scheduler3Failed = false;
        //   //   }
        //   // } else {
        //   //   this.scheduler3Failed;
        //   // }
        // } else {
        //   this.initialfetchdone = false;
        // }
      }
    
    });
    this.activeBusinessSubscription1 = this.activeBusiness.getActiveBusiness().pipe(
      take(1),
    ).subscribe((activ) => {
      if (!activ) {
        this.noActiveGstin = true;
      } else {
        this.userRole = activ.role;
        this.noActiveGstin = false;
      }
    }
    );
    this.checkRole();
    //this.ionViewWillEnter()
  }

  // getGstinList(){
  //    this.activeBusiness.getMyBusiness().subscribe(res => {
  //      localStorage.setItem('businesses', JSON.stringify(res));
  //      this.auth.setLoginIndicator(2)
  //    });
  // }

  toAddGstin() {
    this.router.navigateByUrl('/add-gstin');
  }

  checkRole() {
    this.activeBusinessSubscription2 = this.activeBusiness.getActiveBusiness().pipe(
      take(1),
    ).subscribe((activ) => {
      if (!activ) {
        this.noActiveGstin = true;
      } else {
        this.userRole = activ.role;
        this.noActiveGstin = false;
      }
    }
    );
  }

  ionViewWillEnter() {

    // this.activeBusiness.fetchGstinList().subscribe((response) => {
    //   this.gstins = response;
    //   console.log("gstin3");
    // });
    
    this.business$ = this.activeBusiness.getActiveBusiness().subscribe((active) => {
      if (active === null) { this.noActiveGstin = true; } else {
        this.noActiveGstin = false;
      }
    });
    if (!this.isOrganisation) {
    this.activeBusinessSubscription3 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {
      if (active !== null) {
        this.schedulerState.isSchedulerInProgress(active.gstn).subscribe((data: any) => {
          this.isSchedulerInProgress = data.inprogress;
          this.schedulerTime = data.inprogress ? data.endtime : data.nextrefresh;
          this.schedulerTime = moment(this.schedulerTime, 'DD-MM-YYYY HH:mm:ss');
        });
      }
    });
  }
  }

  viewEwayBillDashboard() {
    this.router.navigate(['/view-eway-dashboard']);
  }

  viewEwayBill(type: any) {
    this.router.navigate([`view-eway/${type}`]);
  }
  viewEwayBillExpiry(type: any) {
    this.router.navigate([`/view-eway-expiry`]);
  }
  viewEwayBillExpiryYesterday(type: any) {
    this.router.navigateByUrl(`/view-eway-expiry-yesterday`);
  }

  viewCEwayBill() {
    this.router.navigate([`view-ceway`]);
  }

  async getFailedEWBs() {
    const dialogRef = this.dialog.open(AutoAlertComponent,{      
      width: '800px',
    });
    dialogRef.afterClosed().subscribe((data: any) => { });

    // this.schedulerService.getFailedEWBs().subscribe(async (response:any) => {
    //   if (response[0].status === 1) {
    //     this.errorList = response[0].response.errorList;
    //     this.errorTotalRecord = response[0].response.totalRecords;
    //     const dialogRef = this.dialog.open(AutoAlertComponent,{
    //       data: {
    //       // header: 'Scheduler 3 Failed',
    //        subHeader: this.errorTotalRecord,
    //        message:  this.errorList,
    //       },
    //       width: '800px',
    //     });
    //     dialogRef.afterClosed().subscribe((data: any) => { });
    //     this.toaster.success(`${response[0].message}`);     
    //   }else {
    //     this.toaster.warning(`${response.message}`);
    //   }
    //   // let message = '';
    //   // if (response.status == 1) {
    //   //   this.errorList = 
    //   //   // const result = response[0]['response'].map((a:any) => a.ewbNo);
    //   //   // result.forEach((ewb:any) => {
    //   //   //   message += '<ul><li>' + ewb + '</li></ul>';
    //   //   // });
    //   // } 
    //   // const dialogRef = this.dialog.open(AutoAlertComponent,{
    //   //   data: {
    //   //    header: 'Scheduler 3 Failed',
    //   //    subHeader: `Total Failed EWBs Count : ${response[1]['response']} `,
    //   //    message: `${message}`,
    //   //   },
    //   //   width: '800px',
    //   // });
    //   // dialogRef.afterClosed().subscribe((data: any) => { });
    //   // const alert = await this.alertController.create({
    //   //   header: 'Scheduler 3 Failed',
    //   //   subHeader: `${response[1]['response']} EWBs failed to extend`,
    //   //   message: `${message}`,
    //   //   buttons: ['OK']
    //   // });
    //   // await alert.present();
    // });
  }
  refreshData($event: any) {
    this.checkRole();
    if (!$event.isOrganisation) {
      this.backend.getCountData($event).subscribe((data) => {
        //this.count = data.response.countData; 
       
        const dashboardData = localStorage.getItem('dashboardData') ? JSON.parse(localStorage.getItem('dashboardData') as string) : null;
        if(dashboardData){
          this.count = data.response.countData;
          this.initialfetchdone = true;
        }else{
          this.count = data.response.countData; 
          this.initialfetchdone = true;      
        }        
        localStorage.setItem('dashboardData', JSON.stringify(this.count));
        
        // if (this.count !== undefined ) {
        //   this.initialfetchdone = true;         
        // } else {
        //   this.initialfetchdone = false;
        // }
        // if (this.count) {
        //   this.initialfetchdone = true;
        // }
        this.backend.getStatsData($event).subscribe((data) => {
          //this.schedularData = data.response;    
         
          const lsDashboardStatsData = localStorage.getItem('dashboardStatsData') ? JSON.parse(localStorage.getItem('dashboardStatsData') as string) : null;
        if(lsDashboardStatsData){
          this.schedularData = data.response;
        }else{
          this.schedularData = data.response;       
        }    
        localStorage.setItem('dashboardStatsData', JSON.stringify(this.schedularData));
        if(this.schedularData[1]?.nature === 2 ){
          this.lastUpdatedCurrentTime = this.schedularData[1].end;
          this.lastUpdatedCurrentTime = moment(this.lastUpdatedCurrentTime, 'DD-MM-YYYY HH:mm:ss');
        }else{
          this.lastUpdatedCurrentTime = null;
        }
        if(this.schedularData[2]?.nature === 3){
          this.scheduler3Failed = true;
        }else{
          this.scheduler3Failed = false;
        }
        if(this.schedularData[3]?.nature === 4){
          this.lastUpdatedHistoricalTime = this.schedularData[3].end;
          this.lastUpdatedHistoricalTime = moment(this.lastUpdatedHistoricalTime, 'DD-MM-YYYY HH:mm:ss');
        }else{
          this.lastUpdatedHistoricalTime = null;
        }

        });
      });

      this.activeBusinessSubscription4 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {
        if (active !== null) {
          this.schedulerState.isSchedulerInProgress(active.gstn).subscribe((data: any) => {
            this.isSchedulerInProgress = data.inprogress;
            this.schedulerTime = data.inprogress ? data.endtime : data.nextrefresh;
            this.schedulerTime = moment(this.schedulerTime, 'DD-MM-YYYY HH:mm:ss');
          });
        }
      });
    } else {
      this.ewbDetailsService.countOrganisationEWB().subscribe((res:any) => {
        //this.count = res; 
        const orgdashboardData = localStorage.getItem('orgDashboardData') ? JSON.parse(localStorage.getItem('orgDashboardData') as string) : null;
        if(orgdashboardData){
          this.count = res.response.countData;
          this.initialfetchdone = true;
        }else{
          this.count = res.response.countData; 
          this.initialfetchdone = true;      
        }        
        localStorage.setItem('orgDashboardData', JSON.stringify(this.count));
        if (this.count !== undefined) {
          //this.initialfetchdone = true;
          // if (this.count[6] && this.count[6].status === 1 && this.count[6].response.length > 0) {
          //   let stats = this.count[6].response.filter((obj: any) => {
          //     return obj.status !== 0;
          //   });
          // }
          // if (this.count[7] && this.count[7].status === 1 && this.count[7].response.length > 0) {
          //   let stats = this.count[7].response.filter((obj: any) => {
          //     return obj.status !== 0;
          //   });
          //   if (stats && stats.length > 0) {
          //     this.lastUpdatedCurrentTime = stats[0].end;
          //     this.lastUpdatedCurrentTime = moment(this.lastUpdatedCurrentTime, 'DD-MM-YYYY HH:mm:ss');

          //     if (stats[0].status === 2) {
          //       this.currentUpdateFailed = true;
          //     } else {
          //       this.currentUpdateFailed = false;
          //     }
          //   } else {
          //     this.lastUpdatedCurrentTime = null;
          //   }
          // } else {
          //   this.lastUpdatedCurrentTime = null;
          // }

          // if (this.count[8] && this.count[8].status === 1 && this.count[8].response.length > 0) {
          //   let stats = this.count[8].response.filter((obj: any) => {
          //     return obj.status !== 0;
          //   });
          //   if (stats && stats.length > 0) {
          //     this.lastUpdatedHistoricalTime = stats[0].end;
          //     this.lastUpdatedHistoricalTime = moment(this.lastUpdatedHistoricalTime, 'DD-MM-YYYY HH:mm:ss');
          //   } else {
          //     this.lastUpdatedHistoricalTime = null;
          //   }
          // } else {
          //   this.lastUpdatedHistoricalTime = null;
          // }

          // if (this.count[9] && this.count[9].status === 1 && this.count[9].response.length > 0) {
          //   let stats = this.count[9].response.filter((obj: any) => {
          //     return obj.status === 2;
          //   });
          //   if (this.count[9].response.length > 0 && this.count[9].response[0].status === 2) {
          //     this.scheduler3Failed = true;
          //   } else {
          //     this.scheduler3Failed = false;
          //   }
          // } else {
          //   this.scheduler3Failed;
          // }
        } else {
          this.initialfetchdone = false;
        }
      })
    }
  }
  

  onRefereshButtonClick() {
    let selectedGstin;
    this.activeBusinessSubscription5 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {
      selectedGstin = active;
    });
    this.refreshData(selectedGstin);
  }

  generateEWB() {
    this.router.navigate(['/generate']);
  }

  

  download(event: any, type: any) {
    event.stopPropagation();
    if ((type === 'PARTB_NOT_UPDATED_FOR_12D' || type === 'EWB_WITHIN_200KM') && this.isOrganisation) {
      this.toaster.warning('Please select GSTIN from above');
      return;
    }
    if (this.isOrganisation) {
      this.router.navigateByUrl('/reports/' + type);
      return;
    } else {
      event.stopPropagation();

      let body: any = {};
      body['type'] = type;
      body['defaultquery'] = {
        'operator': 'and',
        'criterias': []
      }


      this.activeBusinessSubscription6 = this.activeBusiness.getActiveBusiness().pipe(take(1)).subscribe((active) => {

        if (active !== null) {
          if (active.defaultquery !== null) {
            body['defaultquery'] = active.defaultquery;
          }
          this.activeBusiness.downloadEWBdata(body, active.gstn).subscribe((response) => {

            let blob = new Blob(['\ufeff' + response], { type: 'text/csv;charset=utf-8;' });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", `${type}.csv`);
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
            // let downloadData = JSON.stringify(response);
            // var data = new Blob([downloadData], { type: 'text/csv' });
            // saveAs(data, 'text.csv');
          })
        } else {
          this.toaster.warning('Pls Select Gstin');
        }
      });
    }

  }

  ngOnDestroy(): void {
    if (this.business$) {
      this.business$.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.activeBusinessSubscription1) {
      this.activeBusinessSubscription1.unsubscribe();
    }
    if (this.activeBusinessSubscription2) {
      this.activeBusinessSubscription2.unsubscribe();
    }
    if (this.activeBusinessSubscription3) {
      this.activeBusinessSubscription3.unsubscribe();
    }
    if (this.activeBusinessSubscription4) {
      this.activeBusinessSubscription4.unsubscribe();
    }
    if (this.activeBusinessSubscription5) {
      this.activeBusinessSubscription5.unsubscribe();
    }
    if (this.activeBusinessSubscription6) {
      this.activeBusinessSubscription6.unsubscribe();
    }
  }
}
